<template>
  <div
    data-test-id="background_image-layout"
    class="BackgroundImageLayout w-100 h-100 ecod-gradient-background"
    :style="backgroundLayoutStyle"
  >
    <div
      data-test-id="header"
      class="BackgroundImageLayout__header position-absolute w-100 z-index-1001"
    >
      <div data-test-id="notification">
        <NotificationListManager />
      </div>

      <div
        data-test-id="actions"
        class="w-100 d-flex justify-content-end p-3"
      >
        <ui-button
          :face="FACES.transparent"
          :color="GRAYSCALE.white"
          class="LogoutButton mr-2"
          data-test-id="logout-button"
          @clickbutton="logout"
        >
          {{ $t('mfa.action.logout') }}
        </ui-button>
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
import { DELAY } from '@emobg/web-utils';
import { LogoutRoute } from '@Shared/router';
import { useNotifications } from '@/composable/App/Notifications/useNotifications';
import NotificationListManager from '@/components/NotificationList/NotificationListManager';
import { getThemedAssetByCategoryAndKey } from '@/utils/assetsRepository';
import { ASSETS_CATEGORY, PICTURES_KEYNAMES } from '@/constants/assetsRepository';
import { computed } from 'vue';

export default {
  name: 'BackgroundImageLayout',
  components: {
    NotificationListManager,
  },
  props: {
    notification: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { notify } = useNotifications();
    const bgImage = getThemedAssetByCategoryAndKey(ASSETS_CATEGORY.PICTURES, PICTURES_KEYNAMES.MFA_BACKGROUND);
    const backgroundLayoutStyle = computed(() => ({
      backgroundImage: `url(${bgImage})`,
      backgroundSize: 'cover',
    }));
    return {
      notify,
      backgroundLayoutStyle,
    };
  },
  watch: {
    notification(params) {
      this.notify({ delayToClose: DELAY.extraLong, ...params });
    },
  },

  methods: {
    logout() {
      this.$router.push({ name: LogoutRoute.name });
    },
  },
};
</script>

<style lang="scss">
@import "~@emobg/sass/bootstrap/variables";
@import "~@emobg/sass/colors/variables";

.BackgroundImageLayout {
  &__header {
    top: 0;
    left: 0;

    .LogoutButton {
      .Ui-Button {
        outline: 0;

        &:hover {
          color: map-get($motion-grayscale, white);
          outline: solid 1px map-get($motion-grayscale, white) !important;
        }
      }
    }
  }

  &__body {
    max-width: 450px;
  }
}
</style>
